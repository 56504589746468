import Vue from 'vue';
import i18n from "./i18n";
import ExerciseModal from "./webapp/ExerciseModal.vue";
import {vueModalPlugin} from "./modalPlugin";

export function addComparisonPageSelectorViewModel(staticUrl, exercise, comparisonExercise, comparisonStandardsUrl) {
    Vue.use(vueModalPlugin);

    new Vue({
        el: '#comparisonPageSelectorForm',
        i18n,
        data() {
            return {
                exerciseModal: null,
            };
        },
        beforeDestroy() {
            if (this.exerciseModal) {
                this.exerciseModal.$off('on-select');
            }
        },
        mounted() {
            const initialExercises = window.prefill?.find(x => x.request?.url === '/api/exercises')?.response ?? null;
            this.exerciseModal = this.$modal.show(ExerciseModal, {
                staticUrl: staticUrl,
                userId: this.userId,
                standard: true,
                initialExercises,
            });
        },
        methods: {
            showExerciseModal(isComparison) {
                this.isComparison = isComparison;
                this.exerciseModal.$off('on-select');
                this.exerciseModal.$on('on-select', this.selectExercise);
                this.exerciseModal.showModal();
            },
            selectExercise(exerciseId, selectedExercise) {
                if (this.isComparison) {
                    this.changePage(exercise, selectedExercise);
                } else {
                    this.changePage(selectedExercise, comparisonExercise);
                }
            },
            changePage(exercise, comparisonExercise) {
                this.exerciseModal.hideModal();

                // Prevent comparing against oneself
                if (exercise.id !== comparisonExercise.id) {
                    window.location = this.getComparisonUrl(exercise, comparisonExercise);
                }
            },
            getComparisonUrl(exercise, comparisonExercise) {
                return comparisonStandardsUrl
                    .replace('{exercise1}', exercise.name_url)
                    .replace('{exercise2}', comparisonExercise.name_url);
            },
        }
    });
}